import { DateTime } from "ts-luxon"

// Utility function for formatting dates with optional parameters for custom formats
export const formatDate = (
  dateInput: string | Date,
  formatType: "MM-DD-YYYY" |"YYYY-MM-DD"| "Month DD, YYYY" = "MM-DD-YYYY" ,
  timezone: string = "America/New_York" // Default to Eastern Time
): string => {
  // Convert the input to a Luxon DateTime object
  let date: DateTime

  if (typeof dateInput === "string") {
    // If the input is a string, parse it as a DateTime in the specified timezone
    date = DateTime.fromISO(dateInput, { zone: timezone })
  } else {
    // If the input is a Date object, convert it to a DateTime in the specified timezone
    date = DateTime.fromJSDate(dateInput, { zone: timezone })
  }

  // Format the date based on the formatType
  if (formatType === "MM-DD-YYYY") {
    return date.toFormat("MM-dd-yyyy") // Luxon format for MM-DD-YYYY
  } else if (formatType === "YYYY-MM-DD"){
    return date.toFormat("yyyy-MM-dd") // Luxon format for Month DD, YYYY
  } 
  else {
    return date.toFormat("MMMM dd, yyyy") // Luxon format for Month DD, YYYY
  }
}

// Utility function to format time in AM/PM format
export const formatTime = (dateInput: string | Date): string => {
  const date = typeof dateInput === "string" ? new Date(dateInput) : dateInput
  let hours = date?.getHours()
  const minutes = date?.getMinutes()?.toString()?.padStart(2, "0")
  const ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12 || 12
  return `${hours}:${minutes} ${ampm}`
}

// Function to calculate the remaining time in UTC
export const calculateRemainingTime = (
  startTimeStr: string, // Start time in EDT/EST (as a string)
  allocatedTimeInSeconds: number // Allocated time in seconds
): string => {
  // Parse the start time as Eastern Time (EDT/EST)
  const etTime = DateTime.fromISO(startTimeStr, { zone: "America/New_York" });

  // Convert the Eastern Time to UTC
  const utcTime = etTime.toUTC();

  // Get the current time in UTC
  const nowUtc = DateTime.utc();

  // Calculate the difference in seconds between now (UTC) and the start time (UTC)
  const secondsDifference = nowUtc.diff(utcTime, "seconds").seconds;

  // Calculate the remaining time in seconds
  const remainingTimeInSeconds = Math.max(
    0,
    allocatedTimeInSeconds - secondsDifference
  );

  // Format the remaining time
  return formatDuration(remainingTimeInSeconds);
};

// Helper function to format duration (e.g., seconds to "hh:mm:ss")
const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

// Utility function to calculate elapsed time since a given start time
export const calculateElapsedTime = (startTimeStr: string): string => {
  if (!startTimeStr) {
    return ""
  }
  const etTime = DateTime.fromISO(startTimeStr, {
    zone: "America/New_York",
  }).toUTC()
  const secondsDifference = DateTime?.utc()?.diff(etTime, "seconds").seconds
  return formatDuration(Math.max(0, secondsDifference))
}
