import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import withRouter from '../Components/Common/withRouter'
//import Components
import Header from './Header'
import Sidebar from './Sidebar'
//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
    changeSidebarVisibility,
    logoutUser
} from "../slices/thunks"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from 'reselect'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useIdleTimer } from 'react-idle-timer'


const Layout = (props: any) => {

    const dispatch: any = useDispatch()
    const selectLayoutState = (state: any) => state.Layout


    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            leftSidebarType: layout.leftSidebarType,
            layoutModeType: layout.layoutModeType,
            layoutWidthType: layout.layoutWidthType,
            layoutPositionType: layout.layoutPositionType,
            topbarThemeType: layout.topbarThemeType,
            leftsidbarSizeType: layout.leftsidbarSizeType,
            leftSidebarViewType: layout.leftSidebarViewType,
            leftSidebarImageType: layout.leftSidebarImageType,
            preloader: layout.preloader,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
        })
    )
    // Inside your component
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        // preloader,
        sidebarVisibilitytype
    } = useSelector(selectLayoutProperties)

    // layout settings
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType ||
            sidebarVisibilitytype
        ) {
            window.dispatchEvent(new Event('resize'))
            dispatch(changeLeftsidebarViewType(leftSidebarViewType))
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
            dispatch(changeSidebarTheme(leftSidebarType))
            dispatch(changeLayoutMode(layoutModeType))
            dispatch(changeLayoutWidth(layoutWidthType))
            dispatch(changeLayoutPosition(layoutPositionType))
            dispatch(changeTopbarTheme(topbarThemeType))
            dispatch(changeLayout(layoutType))
            dispatch(changeSidebarImageType(leftSidebarImageType))
            dispatch(changeSidebarVisibility(sidebarVisibilitytype))
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        sidebarVisibilitytype,
        dispatch])
    // call dark/light mode
    const onChangeLayoutMode = (value: any) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value))
        }
    }

    const [headerClass, setHeaderClass] = useState<any>("")
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true)
    })
    function scrollNavigation() {
        const scrollup = document.documentElement.scrollTop
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow")
        } else {
            setHeaderClass("")
        }
    }

    const [isIdle, setIsIdle] = useState<boolean>(false)
    const [remainingGraceTime, setRemainingGraceTime] = useState<number>(300) // 5 minutes in seconds

    // Idle timeout logic
    const onIdle = () => {
        setIsIdle(true) // Show modal when user is idle
    }

    const onActive = () => {
        setIsIdle(false) // Hide modal when user becomes active
        setRemainingGraceTime(300) // Reset grace period
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        timeout: 1000 * 15 * 60, // 10 seconds for testing (change to actual idle time, e.g., 15 minutes)
        throttle: 500,
        events:["mousemove"]
    })

    // Grace period countdown
    useEffect(() => {
        let interval: NodeJS.Timeout

        if (isIdle) {
            interval = setInterval(() => {
                setRemainingGraceTime((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(interval)
                        dispatch(logoutUser()) // Logout user when grace period ends
                        return 0
                    }
                    return prevTime - 1
                })
            }, 1000) // Update every second
        }

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [isIdle, dispatch])

    // Format remaining time for display
    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }
  
    return (
        <React.Fragment>
            <div id="layout-wrapper">
            <Modal centered isOpen={isIdle}>
                <ModalHeader>Session Snooze!</ModalHeader>
                <ModalBody>
                    You have {' '}
                    <strong>{formatTime(remainingGraceTime)}</strong> to not get logged out. Move your cursor to continue the session.
                </ModalBody>
            </Modal>
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">{props.children}
                    {/* <Footer /> */}
                </div>
            </div>
        </React.Fragment>

    )
}

Layout.propTypes = {
    children: PropTypes.object,
}

export default withRouter(Layout)