import React, { Fragment, useEffect, useState } from "react";
import { Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import defaultArrow from "./../../assets/images/svg/updownarrow.svg";
import upArrow from "./../../assets/images/uparrow.png";
import downArrow from "./../../assets/images/downarrow.png";

import {
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";

const Filter = ({
  column,
  onSearch,
  filterValue,
}: {
  column: any;
  onSearch: any;
  filterValue: any;
}) => {
  return (
    <>
      <DebouncedInput
        type="text"
        value={filterValue}
        placeholder={" 🔍 Search"}
        className="w-16 border shadow rounded"
        columnId={column.id}
        onSearch={onSearch}
      />
      <div className="h-1" />
    </>
  );
};

const DebouncedInput = ({
  value: initialValue,
  columnId,
  onSearch,
  ...props
}: {
  value: string;
  columnId: string;
  onSearch: any;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(columnId, value); // Trigger search
    }
  };

  return (
    <input
      {...props}
      value={value}
      id={`search-bar-${columnId}`}
      className="form-control p-0 px-2 py-1 w-full min-w-20 search"
      onChange={(e) => setValue(e.target.value)} // Update the local state
      onKeyDown={handleKeyDown}
    />
  );
};

const DropDownValue = 
({
  values,
  onSearch,
  filterValue
}: {
  values: any;
  onSearch: any;
  filterValue: any;
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(filterValue);

  // Handle item select or reset
  const handleSelectItem = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedValue(selected);
    onSearch("status", selected);
  };

  return (
    <select
      className="min-w-28 p-0 px-1 py-1 w-full rounded border block mb-1"
      name="states"
      value={selectedValue}
      onChange={handleSelectItem}
    >
      {/* Placeholder option that acts as a way to select nothing */}
      <option value="">Select an option</option>
      {values?.map((item: any, index: number) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  handleTaskClick?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  iscustomPageSize?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  handleLeadClick?: any;
  handleCompanyClick?: any;
  handleContactClick?: any;
  handleTicketClick?: any;
  isBordered?: any;
  showPagination?: any;
  clickable?: boolean;
  navigateTo?: any;
  isHistoryNavigate?: boolean;
  pageLimit?: any;
  currentPageNumber?: number;
  setCurrentPageNumber?: any;
  onSort?: any;
  onSearch?: any;
  sort?: any;
  search?: any;
  loading?: any;
  navigateData?: any;
}

const TableContainer = ({
  columns,
  data,
  customPageSize,
  theadClass,
  trClass,
  thClass,
  divClass,
  showPagination,
  clickable,
  navigateTo,
  isHistoryNavigate = true,
  pageLimit,
  currentPageNumber,
  setCurrentPageNumber,
  onSort,
  sort,
  onSearch,
  search,
  loading,
  navigateData,
}: TableContainerProps) => {
  const history = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table;

  useEffect(() => {
    if (Number(customPageSize)) {
      setPageSize(Number(customPageSize));
    }
  }, [customPageSize, setPageSize]);

  // Sorting Handler
  const handleSortClick = (columnId: string) => {
    const column = table.getColumn(columnId);

    // Check if the column allows sorting
    if (column?.columnDef?.enableSorting === false) {
      return; // Prevent sorting if not enabled
    }

    // If no column has been sorted yet (initial state), sort in ascending order
    if (!sort?.fieldName) {
      onSort(columnId, "asc"); // Inform the parent component that the column is now sorted asc.
      table.setSorting([{ id: columnId, desc: false }]); // Ascending order
    } else {
      // If the current column is already the one being sorted, toggle sorting direction
      if (sort?.fieldName === columnId) {
        switch (sort.value) {
          case "asc":
            onSort(columnId, "desc"); // Inform the parent component that the column is now sorted desc.
            table.setSorting([{ id: columnId, desc: true }]); // Sort in descending order
            break;
          case "desc":
            onSort(columnId, "none"); // Inform the parent component that the column is now not sorted.
            table.setSorting([]); // Clear sorting
            break;

          default:
            onSort(columnId, "asc"); // Inform the parent component that the column is now sorted asc.
            table.setSorting([{ id: columnId, desc: false }]); // Ascending order
            break;
        }
      } else {
        // If a different column is clicked, reset to ascending sort
        onSort(columnId, "asc"); // Inform the parent component that the column is now sorted asc.
        table.setSorting([{ id: columnId, desc: false }]); // Sort the new column in ascending order
      }
    }
  };

  const getSearchValue = (columnId: string) => {
    // Find the matching value for the columnId
    const match = search?.find((item: any) => item?.fieldName === columnId);
    return match ? match?.value : ""; // Default to an empty string if no match
  };

  return (
    <Fragment>
      <div className="table-responsive text-sm">
        <div className={divClass}>
          <Table
            hover={clickable}
            className={"table table-striped table-nowrap align-middle mb-0"}
          >
            <thead className={theadClass}>
              {getHeaderGroups().map((headerGroup: any) => (
                <tr className={trClass} key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <th key={header.id} className={thClass}>
                        {header.isPlaceholder ? null : (
                          <React.Fragment>
                            {/* Render header label */}
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {/* Conditionally render sorting icon */}
                            <button onClick={() => handleSortClick(header.id)}>
                              {header?.column?.columnDef?.enableSorting &&
                                (sort?.fieldName !== header.id ? (
                                  <img
                                    src={defaultArrow}
                                    className="w-2.5 ml-2"
                                    alt="default sort"
                                  />
                                ) : (
                                  sort?.fieldName === header.id &&
                                  (sort?.value === "asc" ? (
                                    <img
                                      src={upArrow}
                                      className="w-2.5 ml-2"
                                      alt="default sort"
                                    />
                                  ) : (
                                    <img
                                      src={downArrow}
                                      className="w-2.5 ml-2"
                                      alt="default sort"
                                    />
                                  ))
                                ))}
                            </button>
                            {header.column.columnDef.enableColumnFilter ? (
                              <Filter
                                column={header.column}
                                onSearch={onSearch}
                                filterValue={getSearchValue(header.id)}
                              />
                            ) : header.column.columnDef.enableDropDown ? (
                              <DropDownValue
                                values={header.column.columnDef?.dropDownValues}
                                onSearch={onSearch}
                                filterValue={getSearchValue(header.id)}
                              />
                            ) : (
                              header.column.columnDef.disableSort && (
                                <div className="h-4" />
                              )
                            )}
                          </React.Fragment>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={10}>
                    <div className="flex justify-center items-center h-40">
                      <div className="loader" />
                    </div>
                  </td>
                </tr>
              ) : data.length !== 0 ? (
                getRowModel().rows.map((row: any) => (
                  <tr
                    key={row.id}
                    className={`${clickable && "cursor-pointer"}`}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <td
                        key={cell.id}
                        {...(clickable && {
                          onClick: () => {
                            if (isHistoryNavigate) {
                              let additionalData = { key: data[row.id] };

                              if (navigateData) {
                                additionalData = {
                                  key: {
                                    id: data[row.id],
                                    navigateData,
                                  },
                                };
                              }

                              //   inboundDate: item?.inboundDate,
                              // asOfEndOfDay: selectedDate,
                              history(navigateTo, { state: additionalData });
                              history(navigateTo, { state: additionalData });
                            }
                          },
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center py-4 text-gray-600" colSpan={10}>
                    You are all caught up!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {!currentPageNumber && showPagination && data.length > 5 && (
        <Row className="align-items-center mt-2 mb-2 px-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Total Of<span className="fw-semibold ms-1">{data.length}</span>{" "}
              Results
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li
                className={
                  !getCanPreviousPage() ? "page-item disabled" : "page-item"
                }
              >
                <button className="page-link" onClick={previousPage}>
                  <i className="ri-arrow-left-s-line" />
                </button>
              </li>
              {getPageOptions().map((item: any, key: number) => (
                <li className="page-item" key={key}>
                  <button
                    className={
                      getState().pagination.pageIndex === item
                        ? "page-link active"
                        : "page-link"
                    }
                    onClick={() => setPageIndex(item)}
                  >
                    {item + 1}
                  </button>
                </li>
              ))}
              <li
                className={
                  !getCanNextPage() ? "page-item disabled" : "page-item"
                }
              >
                <button className="page-link" onClick={nextPage}>
                  <i className="ri-arrow-right-s-line" />
                </button>
              </li>
            </ul>
          </div>
        </Row>
      )}

      {currentPageNumber && showPagination && (
        <Row className="align-items-center mt-2 mb-2 px-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Showing{" "}
              <span className="fw-semibold ms-1">
                {(currentPageNumber - 1) * pageLimit?.page_size + 1 || 0} -{" "}
                {Math.min(
                  currentPageNumber * pageLimit?.page_size,
                  pageLimit?.total_items
                ) || 0}
              </span>{" "}
              of {pageLimit?.total_items || 0} Total Results
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              {/* First Page Button */}
              <li
                className={
                  currentPageNumber <= 1 ? "page-item disabled" : "page-item"
                }
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPageNumber(1)}
                  disabled={currentPageNumber <= 1}
                >
                  {"<<"}
                </button>
              </li>

              {/* Previous Page Button */}
              <li
                className={
                  currentPageNumber <= 1 ? "page-item disabled" : "page-item"
                }
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPageNumber(currentPageNumber - 1)}
                  disabled={currentPageNumber <= 1}
                >
                  <i className="ri-arrow-left-s-line" />
                </button>
              </li>

              {/* Page Numbers */}
              {(() => {
                const pages = [];
                const totalPages = pageLimit?.total_pages || 1;

                if (currentPageNumber > 3) {
                  pages.push(1, 2, 3);
                  pages.push("...");
                  const startPage = Math.max(currentPageNumber - 2, 4);
                  const endPage = Math.min(currentPageNumber + 2, totalPages);
                  for (let i = startPage; i <= endPage; i++) {
                    pages.push(i);
                  }
                } else {
                  for (let i = 1; i <= currentPageNumber; i++) {
                    pages.push(i);
                  }
                }

                return pages.map((page, index) => (
                  <li className="page-item" key={index}>
                    {page === "..." ? (
                      <button className="page-link disabled">...</button>
                    ) : (
                      <button
                        className={
                          currentPageNumber === page
                            ? "page-link active"
                            : "page-link"
                        }
                        onClick={() => setCurrentPageNumber(page)}
                      >
                        {page}
                      </button>
                    )}
                  </li>
                ));
              })()}

              {/* Next Page Button */}
              <li
                className={
                  currentPageNumber === pageLimit?.total_pages
                    ? "page-item disabled"
                    : "page-item"
                }
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                  disabled={
                    currentPageNumber === pageLimit?.total_pages ||
                    pageLimit?.total_pages === 0
                  }
                >
                  <i className="ri-arrow-right-s-line" />
                </button>
              </li>

              {/* Last Page Button */}
              <li
                className={
                  currentPageNumber === pageLimit?.total_pages
                    ? "page-item disabled"
                    : "page-item"
                }
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPageNumber(pageLimit?.total_pages)}
                  disabled={
                    currentPageNumber === pageLimit?.total_pages ||
                    pageLimit?.total_pages === 0
                  }
                >
                  {">>"}
                </button>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

export default TableContainer;
